const availableTeams = [
    { value: 'labs', label: 'Labs' },
    { value: 'labs-sd', label: 'Labs - šicí dílna' },
    { value: 'coffee', label: 'Coffee' },
    { value: 'art', label: 'Art' },
    { value: 'stage', label: 'Stage' },
    { value: 'feste', label: 'Divadlo FESTE' },
    { value: 'events', label: 'Events' },
    { value: 'industra-general', label: 'Provoz' },
]

const categories = {
    labs: [
        { value: 'it', label: 'IT vybavení'},
        { value: 'tools', label: 'Nářadí'},
        { value: 'cables', label: 'Kabely'},
        { value: 'mechanic', label: 'Mechanické vybavení'},
        { value: 'electric', label: 'Elektrické vybavení'}
    ]
}

export {categories, availableTeams};
