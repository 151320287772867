import React, {Component} from 'react'
import {availableTeams} from "./teams";
import config from "./config.json"


class Annotate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            annotated: null
        };

        this.getNewTask()
    }

    render() {
        if(!this.props.selectedTeam){
            return "Vyber svůj tým"
        }

        /*if(this.state.annotated === null){
            return "Loading..."
        }*/

        return <AnnotateUI team={this.props.selectedTeam} data={this.state.annotated} key={this.state.annotated?.tag}
                           onAnnotationCompleted={this.getNewTask}/>

    }

    getNewTask = () => {
        this.setState({annotated: null});
        fetch(config.yawms_server + "/annotation/task?team=" + this.props.selectedTeam)
            .then(res => res.json())
            .then(res => this.setState({annotated: res}))
    }

}

class AnnotateUI extends Component {
    constructor(props) {
        super(props);
        this.state = {
            description: ""
        }
    }

    onTeamSelect = (team) => {
        this.setState({
            selectedTeam: team
        })
    };

    isSubmitable = () => {
        if(this.state.selectedTeam != null && this.state.selectedTeam !== this.props.team){
            return true;
        }

        return this.state.selectedTeam && this.state.description && this.state.description
    };

    handleSubmit = () => {
        let result = {team: this.state.selectedTeam};
        if(this.state.description.length > 0){
            result.description = this.state.description
        }

        fetch(config.yawms_server + "/items/" + this.props.data.tag + "/edit", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(result),
        }).then(() => {
            this.props.onAnnotationCompleted()
        })
    };

    render() {
        let teamButtons = [];
        for (let team of availableTeams) {
            teamButtons.push(<button key={team.value} onClick={() => this.onTeamSelect(team.value)}>{team.label}</button>)
        }


        let selectionPart = null;

        if(this.state.selectedTeam === this.props.team){
            selectionPart = <div>
                Zadej prosím popis: <br/>
                <input value={this.state.description} onChange={event => this.setState({description: event.target.value})} />
            </div>
        }




        return <>
            <div className="panel"><img alt="Fotografie předmětu k anotaci" src={this.props?.data?.imageUri}/></div>
            <div className="panel">
                <div>{teamButtons}</div>
                <div>{selectionPart}</div>
                <div>{this.isSubmitable() && <button onClick={this.handleSubmit}>Submit</button>}</div>
            </div>
        </>


    }
}


export default Annotate
