import React, {Component} from 'react'
import Select from 'react-select'
import {availableTeams} from "./teams";


class TeamSelector extends Component {
    render() {
        return <Select className="selector" isClearable={true} options={availableTeams} value={this.props.value}
                       onChange={(team) => {this.props.onChange(team?.value ?? null)}}/>
    }
}

class CategorySelector extends Component {
    render() {
        console.log(this.props.categories)
        return <Select className="selector" isClearable={true} options={this.props.categories} value={this.props.value}
                       onChange={(cat) => {this.props.onChange(cat?.value ?? null)}} />
    }
}

export {CategorySelector, TeamSelector}