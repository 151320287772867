import './App.css';
import React, {Component} from "react";
import {TeamSelector} from "./Selectors";
import Annotate from "./Annotate";

class App extends Component{
    constructor(props) {
        super(props);
        this.state = {
            selectedTeam: null,

        }
    }

    render() {
        return (
            <div className="App">
                <header className="header">
                    <h1>Anotátor v1</h1>
                    <TeamSelector team={this.state.selectedTeam} onChange={(team) => {this.setState({selectedTeam: team})}}/>
                </header>
                {this.state.selectedTeam && <Annotate selectedTeam={this.state.selectedTeam} key={this.state.selectedTeam}/>}
            </div>
        );
    }


}

export default App;
